import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import BackgroundImage from "gatsby-background-image"
import Layout from "../components/layout/basic"
import SEO from "../components/global/SEO"
import FormFeedback from "../components/global/FormFeedback"

// Bootstrap
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
// import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"

// TimeLine
import {
  FcApproval,
  FcParallelTasks,
  FcMindMap,
  FcGenealogy,
  FcBriefcase,
  FcAssistant,
  FcGlobe,
  FcViewDetails,
} from "react-icons/fc"
import {
  // Card,
  ActivityTimeline,
  TimelineMarker,
} from "react-rainbow-components"

const iconStyles = { width: 32, height: 32 }

const KontaktyPage = ({ data }) => {
  const backgroundImage = [
    data.contentfulAsset.fluid,
    `radial-gradient(at 50% 100%, rgba(181, 45, 168, 0.6), rgba(15, 1, 94, 0.75))`,
  ].reverse()
  return (
    <Layout>
      <SEO
        title="Контакти Нового Телекому"
        description="Контакти Інтернет-Провайдера Новий Телеком."
        image=""
        url="/kontakty/"
      />
      <BackgroundImage fluid={backgroundImage}>
        <div className="hero-background-light py-5 text-white">
          <Container className="my-5">
            <h1>Компанія Новий Телеком</h1>
            <div className="hero-slogan mt-4">
              Інформація про Компанію Новий Телеком, адреса офісу на карті,
              телефони технічної підтримки, форма зворотного зв'язку.
            </div>
          </Container>
        </div>
      </BackgroundImage>
      <Container className="my-5">
        <Row>
          <Col lg={6} md={6} sm={12}>
            <div className="h4">Зателефонувати</div>
            <Button variant="light" href="tel:+380442774277">
              +380(44) 277-4-277
            </Button>

            <div className="h4 mt-3">Написати</div>
            <ul className="list-unstyled">
              <li>support@newtele.com.ua – технічна підтримка</li>
              <li>newtelecomro2@gmail.com – для юр. осіб</li>
              <li>
                finance@newtele.com.ua – бухгалтерія (
                <Link to="/sposoby-oplaty/">як оплатити?</Link>)
              </li>
            </ul>
            <h4>Звернутися онлайн</h4>
            <FormFeedback />
          </Col>
          <Col lg={6} md={6} sm={12}>
            <div className="h4">Адреса</div>
            <p>
              Україна
              <br />
              Київська область, Бучанський район, с. Гореничі
              <br />
              вул. Столична 1-А, Офіс 2.
            </p>
            <h3>Розклад роботи офісу</h3>
            <p>
              Понеділок—пятниця 9:00—18:00
              <br />
              Субота, Неділя – вихідні.
            </p>
            <p>(044) 277-4-277 – технічна підтримка цілодобово</p>
            <a
              href="https://g.page/newtelecomua?share"
              target="_blank"
              rel="noreferrer noopener"
            >
              <Img
                fluid={data.contentfulAsset.fluid}
                className="w-100 kontakty-map-image mb-3"
                alt="новий телеком на карті"
              />
            </a>
          </Col>
        </Row>
      </Container>

      <Container className="my-5">
        <h2 className="mb-3">Про компанію Новий Телеком</h2>
        <p>
          Історія розвитку Нового Телекому показує приклад руху місцевої
          регіональної компанії до великої мети. Ми дякуємо всім, хто бере
          участь у створенні цієї історії, і запрошуємо створювати історію
          разом!
        </p>
        <p>
          Для нас головне - це люди, які користуються нашими послугами, і ті,
          завдяки кому компанія працює як годинник, єдиним механізмом.
        </p>
        <p>Наші корпоративні цінності:</p>
        <ul>
          <li>
            якість сервісу, адже ми тут живемо і самі користуємося своїм
            продуктом,
          </li>
          <li>повага до людей і порядність,</li>
          <li>відповідальність і постійне поліпшення.</li>
        </ul>
        <p>
          Звичайно, ми стикаємося з ситуаціями, властивими всім провайдерам, але
          основною відмінністю вважаємо відповідальність, яку беремо на себе і
          доводимо партнерам та клієнтам, що справляємося з усіма складнощами і
          на нас можна покластися.
        </p>
        <div className="my-4">
          <h3 className="my-3">Історія Нового Телекому</h3>
          <ActivityTimeline>
            <TimelineMarker
              icon={<FcApproval style={iconStyles} />}
              label="Початок діяльності ТОВ “Новий телеком”"
              datetime="2010 рік"
              description="Стартом для бізнесу стала необхідність високошвидкісного Інтернету в рідному селі. Все почалося з чотирьох співробітників, включаючи бухгалтера. Укладено перший договір на канал зв'язку в 100 Мбіт. Початок підключень перших клієнтів. У буквальному сенсі - робимо як для себе."
              as="div"
              tag="div"
            />
            <TimelineMarker
              icon={<FcParallelTasks style={iconStyles} />}
              label="Власні IP-адреси"
              datetime="2011 рік"
              description="Отримано перші, власні IP-адреси. Створено сайт."
            />
            <TimelineMarker
              icon={<FcMindMap style={iconStyles} />}
              label="Власна магістральна лінія"
              datetime="2012 рік"
              description="Завершено будівництво власної магістральної лінії зв'язку, Київ-Гореничі."
            />
            <TimelineMarker
              icon={<FcGenealogy style={iconStyles} />}
              label="Нові Оптичні канали"
              datetime="2013 рік"
              description="Запущені нові оптичні канали зв'язку. Ми стали учасником української точки обміну трафіком UA-IX, Giganet. Збільшено канал зв'язку до 10 Гбіт. Укладено перший договір з платіжною системою ukrpays. Перші підключення в приватному секторі за технологією PON."
            />
            <TimelineMarker
              icon={<FcAssistant style={iconStyles} />}
              label="Технічна підтримка 24/7"
              datetime="2014 рік"
              description="Запущена техпідтримка абонентів 24/7. Пріоритет високого обслуговування на дійсних абонентів. Робота з великими юр.особами."
            />
            <TimelineMarker
              icon={<FcBriefcase style={iconStyles} />}
              label="Ukr Link"
              datetime="2017 рік"
              description="Злиття з регіональним провайдером Ukr Link."
            />
            <TimelineMarker
              icon={<FcBriefcase style={iconStyles} />}
              label="VVM"
              datetime="2018 рік"
              description="Злиття з меншим регіональним провайдером VVM."
            />
            <TimelineMarker
              icon={<FcGlobe style={iconStyles} />}
              label="Оптичне кільце в регіоні"
              datetime="2019 рік"
              description="Збудували власне оптичне кільце в регіоні. Реалізовано проєкт з побудови оптичного кільця, високої доступності між вузлами зв'язку."
            />
            <TimelineMarker
              icon={<FcViewDetails style={iconStyles} />}
              label="Зареєстровано торгову марку"
              datetime="2021 рік"
              description="Оновлено домен сайту в зоні UA. Оновлено сайт під мобільні пристрої."
            />
          </ActivityTimeline>
        </div>
        <p>
          За перші десять років існування ми стали лідером в регіоні, зіткнулися
          з викликами в управлінні компанії та складнощами в масштабуванні. Тому
          активно взялися за підвищення кваліфікації та навчання всіх
          співробітників - від менеджерів технічної підтримки до монтажників,
          керівників і бухгалтерів. Це дозволить нам підняти рівень сервісу для
          наших клієнтів.
        </p>
        <p>
          Ставимо перед собою амбітні цілі і бачимо, що їх досягаємо.
          Справляючись з труднощами, стаємо кращими, впевненішими і сильнішими.
        </p>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  {
    contentfulAsset(id: { eq: "d7bdce6c-6ff6-570b-9193-cf412dc10cdf" }) {
      fixed(width: 400) {
        ...GatsbyContentfulFixed_withWebp
      }
      fluid(maxWidth: 700) {
        ...GatsbyContentfulFluid_withWebp
      }
    }
  }
`

export default KontaktyPage
