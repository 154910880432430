import React, { useState } from "react"
import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"

const FormFeedback = ({ slug, title, price }) => {
  const [validated, setValidated] = useState(false)

  const handleSubmit = event => {
    const form = event.currentTarget
    if (form.checkValidity() === false) {
      event.preventDefault()
      event.stopPropagation()
    }
    setValidated(true)
  }

  return (
    <Form
      data-netlify="true"
      netlify-honeypot="bot-field"
      action="/dyakuemo/?form_type=feedback"
      method="POST"
      name={`feedback-${slug}`}
      form-name={`feedback-${slug}`}
      noValidate
      validated={validated}
      onSubmit={handleSubmit}
      id="feedbackForm"
      className="mb-4"
    >
      <Form.Group hidden>
        <input
          type="hidden"
          name="form-name"
          value={`feedback-${slug}`}
          // id="noFillField"
        />
        <Form.Label>
          Поле для незаполнения <Form.Control type="text" name="bot-field" />
        </Form.Label>
        <Form.Label>
          Сторінка
          <Form.Control
            type="hidden"
            name="page"
            placeholder="Сторінка"
            value={title}
            // id="PageField"
          />
        </Form.Label>
      </Form.Group>

      <Form.Group controlId="formTextarea">
        {/* <Form.Label>Напішіть повідомлення *</Form.Label> */}
        <Form.Control
          as="textarea"
          type="text"
          rows={3}
          placeholder="Напішіть ваше повідомлення ..."
          name="message"
          required
        />
        {/* <Form.Text className="text-muted">
          Ми відповімо протягом дня, як його отримаємо
        </Form.Text> */}
      </Form.Group>

      <Form.Group controlId="formPhone">
        {/* <Form.Label>Ваш телефон для зв`язку *</Form.Label> */}
        <Form.Control
          type="tel"
          name="tel"
          placeholder="+380..."
          // id="PhoneField"
          required
        />
        <Form.Text className="text-muted">
          Вкажіть номер телефону для зв'язку *
        </Form.Text>
        <Form.Control.Feedback type="invalid">
          вкажіть номер, будь ласка
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group controlId="formName">
        {/* <Form.Label>Ім`я</Form.Label> */}
        <Form.Control
          type="text"
          name="name"
          placeholder="Ваше ім`я"
          // id="NameField"
        />
        <Form.Text className="text-muted">Як до вас звернутися?</Form.Text>
      </Form.Group>

      <Form.Group controlId="formCheckbox">
        <Form.Check
          type="checkbox"
          name="agree"
          label="згода з правилами сайту"
          id="AgreeField"
          required
        />
        <Form.Control.Feedback type="invalid">
          прийміть, будь ласка, правила сайту *
        </Form.Control.Feedback>
      </Form.Group>
      <Button type="submit" variant="danger" size="lg" block>
        Відправити
      </Button>
    </Form>
  )
}

FormFeedback.defaultProps = {
  slug: `/`,
  title: `Заголовок`,
  price: `0`,
}

export default FormFeedback
